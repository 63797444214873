const { create } = require('zustand');

const useStore = create((set) => ({
  spxData: [],        // Store for SPX data
  spxGexData: [],     // Store for SPX GEX data
  vvixData: [],       // Store for VVIX data
  expectedMoveData: [], // Store for calculated expected move data for days 1-5

  upperBoundPercent: null,   // Upper bound percentage
  lowerBoundPercent: null,   // Lower bound percentage
  spxUpperBound: null,       // SPX upper bound price
  spxLowerBound: null,       // SPX lower bound price

  // Stores for GEX statistics for VVIX Below
  gexStatisticsDay1_vvixBelow: {},
  gexStatisticsDay2_vvixBelow: {},
  gexStatisticsDay3_vvixBelow: {},
  gexStatisticsDay4_vvixBelow: {},
  gexStatisticsDay5_vvixBelow: {},

  // Stores for GEX statistics for VVIX Above
  gexStatisticsDay1_vvixAbove: {},
  gexStatisticsDay2_vvixAbove: {},
  gexStatisticsDay3_vvixAbove: {},
  gexStatisticsDay4_vvixAbove: {},
  gexStatisticsDay5_vvixAbove: {},

  // Setter for SPX data
  setSpxData: (data) => {
    set({ spxData: data });
  },

  // Setter for SPX GEX data
  setSpxGexData: (data) => {
    set({ spxGexData: data });
  },

  // Setter for VVIX data
  setVvixData: (data) => {
    set({ vvixData: data });
  },

  // Setter for calculated expected move data
  setExpectedMoveData: (data) => {
    set({ expectedMoveData: data });
  },

  // Setter for GEX statistics for VVIX Below for a specific day
  setGexStatisticsByDay_vvixBelow: (day, statistics) => {
    set((state) => ({
      ...state,
      [`gexStatisticsDay${day}_vvixBelow`]: statistics,
    }));
  },

  // Setter for GEX statistics for VVIX Above for a specific day
  setGexStatisticsByDay_vvixAbove: (day, statistics) => {
    set((state) => ({
      ...state,
      [`gexStatisticsDay${day}_vvixAbove`]: statistics,
    }));
  },

  // Setter for expected move data based on selected day range (1-5)
  setExpectedMoveDataByDay: (dayRange, data) => {
    set((state) => ({
      expectedMoveData: state.expectedMoveData.map((entry, index) => {
        const dayKey = `day${dayRange}Diff`;
        return {
          ...entry,
          [dayKey]: data[index] ? data[index][dayKey] : null,
        };
      }),
    }));
  },

  // Setter for upper and lower bound percentages and SPX prices
  setBoundsData: (upperPercent, lowerPercent, spxUpper, spxLower) => {
    set({
      upperBoundPercent: upperPercent,
      lowerBoundPercent: lowerPercent,
      spxUpperBound: spxUpper,
      spxLowerBound: spxLower,
    });
  },

  // Function to update SPX, SPX GEX, and VVIX data
  updateMarketData: (spxData, spxGexData, vvixData) => {
    console.log('Updating Market Data:');
    set({
      spxData: spxData || [],
      spxGexData: spxGexData || [],
      vvixData: vvixData || [],
    });
  },
}));

module.exports = useStore; // Export the Zustand store properly









// //Basic Working Code
// const { create } = require('zustand');
//
// const useStore = create((set) => ({
//   spxData: [],        // Store for SPX data
//   spxGexData: [],     // Store for SPX GEX data
//   vvixData: [],       // Store for VVIX data
//   expectedMoveData: [], // Store for calculated expected move data
//
//   // Setter for SPX data
//   setSpxData: (data) => {
//     set({ spxData: data });
//   },
//
//   // Setter for SPX GEX data
//   setSpxGexData: (data) => {
//     set({ spxGexData: data });
//   },
//
//   // Setter for VVIX data
//   setVvixData: (data) => {
//     set({ vvixData: data });
//   },
//
//   // Setter for calculated expected move data
//   setExpectedMoveData: (data) => {
//     set({ expectedMoveData: data });
//   },
//
//   // Function to update SPX, SPX GEX, and VVIX data
//   updateMarketData: (spxData, spxGexData, vvixData) => {
//     console.log('Updating Market Data:');
//     set({
//       spxData: spxData || [],
//       spxGexData: spxGexData || [],
//       vvixData: vvixData || [],
//     });
//   },
// }));
//
// module.exports = useStore; // Export the Zustand store properly













