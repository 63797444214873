import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode, LineStyle } from 'lightweight-charts';
import useStore from '../Store/gamma_expected_move_data_store';
import './styles.css';



const GEM_Chart = () => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const seriesRef = useRef(null);
  const upperBoundRef = useRef(null); // Upper bound series
  const lowerBoundRef = useRef(null); // Lower bound series
  const [upperBoundPercent, setUpperBoundPercent] = useState(null);
  const [lowerBoundPercent, setLowerBoundPercent] = useState(null);
  const [spxUpperBound, setSpxUpperBound] = useState(null);
  const [spxLowerBound, setSpxLowerBound] = useState(null);
  const mostRecentSeriesRef = useRef(null);



  // Access the expectedMoveData and GEX statistics from Zustand
  const pairedData = useStore((state) => state.pairedData);  // Access pairedData
  const expectedMoveData = useStore((state) => state.expectedMoveData);
  const vvixData = useStore((state) => state.vvixData); // For determining the VVIX scenario
  // Add the rest for days 2-5 as needed

  const [selectedDay, setSelectedDay] = useState(1); // Default to 1-day expected move
  const [isChartInitialized, setIsChartInitialized] = useState(false);

  // Handle day change for the dropdown
  // Handle day change for the dropdown
  const handleDayChange = (event) => {
    setSelectedDay(parseInt(event.target.value)); // Update the selected day range
  };

  // Function to map GEX value to the correct GEX range key
  const mapGexToRange = (gexValue) => {
    if (gexValue < -2e9) return 'Negative (< -2B)';
    if (gexValue >= -2e9 && gexValue < -1e9) return '-2B to -1B';
    if (gexValue >= -1e9 && gexValue < 0) return '-1B to 0B';
    if (gexValue >= 0 && gexValue < 1e9) return '0B to 1B';
    if (gexValue >= 1e9 && gexValue < 2e9) return '1B to 2B';
    if (gexValue >= 2e9 && gexValue < 3e9) return '2B to 3B';
    if (gexValue >= 3e9 && gexValue < 4e9) return '3B to 4B';
    if (gexValue >= 4e9 && gexValue < 5e9) return '4B to 5B';
    if (gexValue >= 5e9 && gexValue < 6e9) return '5B to 6B';
    if (gexValue >= 6e9 && gexValue < 7e9) return '6B to 7B';
    if (gexValue >= 7e9) return '7B+';
    return null; // Fallback in case of invalid gexValue
  };

  useEffect(() => {
    if (!expectedMoveData || expectedMoveData.length === 0) {
      console.log('No expected move data available for plotting.');
      return;
    }

    if (!chartContainerRef.current) {
      console.error('No chart container available.');
      return;
    }

    if (!chartRef.current && chartContainerRef.current) {
      // Initialize the chart
      chartRef.current = createChart(chartContainerRef.current, {
        width: window.innerWidth * 0.95,
        height: window.innerHeight * 0.95,
        timeScale: {
          visible: true,
          fixRightEdge: true,
          fixLeftEdge: true,

          tickMarkFormatter: (index) => {
            const gexValue = sortedData.find((d, i) => i === index)?.gexValue || index;
            if (gexValue >= 1e9 || gexValue <= -1e9) {
              return (Math.floor(gexValue / 1e9)) + 'B';
            } else if (gexValue < 1e9 && gexValue > 0) {
              return '0B';
            } else if (gexValue < 0 && gexValue > -1e9) {
              return '-0B';
            }
            return gexValue.toString();
          },
        },
        layout: {
          background: { type: 'solid', color: '#000000' },
          textColor: '#d1d4dc',
        },
        grid: {
          vertLines: { color: 'rgba(42, 46, 57, 0)' },
          horzLines: { color: 'rgba(87, 87, 87, 0.23)' },
        },
        crosshair: {
          mode: CrosshairMode.Normal,
          vertLine: {
            width: 8,
            color: '#C3BCDB44',
            style: LineStyle.Solid,
            labelBackgroundColor: '#B18800',
                // Add this to hide the label on the X-axis crosshair
            labelVisible: false,  // This disables the label on the X-axis
          },
          horzLine: {
            width: 8,
            color: '#C3BCDB44',
            style: LineStyle.Solid,
            labelBackgroundColor: '#B18800',
          },
        },
        rightPriceScale: {
          scaleMargins: { top: 0.2, bottom: 0.2 },
        },
      });

      // Create a line series that will simulate a scatter plot
      seriesRef.current = chartRef.current.addLineSeries({
        color: 'rgba(0,0,0,0)',
        lineWidth: 2,
        crossHairMarkerVisible: true,
        lastValueVisible: false,
        priceLineVisible: false,
      });

      // Create line series for upper and lower bounds
      upperBoundRef.current = chartRef.current.addLineSeries({
        color: 'rgb(255, 255, 255)',
        lineWidth: 2,
        lastValueVisible: false,       // Hides the last value label
        priceLineVisible: false,       // Hides the horizontal price line
      });

      lowerBoundRef.current = chartRef.current.addLineSeries({
        color: 'rgb(255, 255, 255)', // Blue for lower bound
        lineWidth: 2,
        lastValueVisible: false,       // Hides the last value label
        priceLineVisible: false,       // Hides the horizontal price line
      });
      // NEW: Create a line series specifically for the most recent point
      mostRecentSeriesRef.current = chartRef.current.addLineSeries({
        color: 'rgba(0,0,0,0)', // Yellow color for the most recent point
        lineWidth: 2, // No line, only markers
        crossHairMarkerVisible: true,
        lastValueVisible: false,
        priceLineVisible: false,
      });
    }

    const getColorForGexValue = (gexValue) => {
      const capValue = 9e9; // Cap at 9 billion
      const opacity = 1; // Full opacity for vibrant colors

      if (gexValue >= 0) {
        let normalizedGex = Math.min(gexValue / capValue, 1); // Normalize between 0 and 1
        const hue = 0 + (normalizedGex * 180); // Gradual transition from red (0) to cyan-blue (180 degrees)
        const lightness = 55 + (normalizedGex * 5); // Adjust lightness for more intense colors, capped at 60%
        return `hsla(${hue}, 100%, ${lightness}%, ${opacity})`;

      } else {
        let normalizedGex = Math.min(Math.abs(gexValue) / 1e9, 1); // Normalize for negative values
        const hue = 0; // Pure red
        const lightness = 50 - (normalizedGex * 15); // Darken the red for more negative values
        return `hsla(${hue}, 100%, ${lightness}%, ${opacity})`;
      }
    };

    const fillMissingValues = (entry, dayKey) => {
      return entry[dayKey] !== null && entry[dayKey] !== undefined
        ? entry[dayKey]
        : 0; // Default to 0 if value is missing
    };

    const sortedData = expectedMoveData.slice().sort((a, b) => a.gexValue - b.gexValue);
    // console.log(sortedData)


    // Access the most recent data (first element in the time series)
    const mostRecentData = vvixData[0]; // First element in the array (most recent)

    const mostRecentAvgZScore = parseFloat(mostRecentData.vvix_avg_z_score);
    const mostRecentSuperSmoothZScore = parseFloat(mostRecentData.vvix_super_smooth_z_score);

    const vvixScenario = mostRecentAvgZScore < mostRecentSuperSmoothZScore ? 'vvixBelow' : 'vvixAbove';
    console.log(`VVIX Scenario: ${vvixScenario}`);

;
    // Choose GEX statistics based on the selected VVIX scenario and day
    const selectedGexStatistics = useStore.getState()[`gexStatisticsDay${selectedDay}_${vvixScenario}`];

    // // Add logging to ensure correct data
    // console.log('Selected GEX statistics:', selectedGexStatistics);

    if (!selectedGexStatistics || Object.keys(selectedGexStatistics).length === 0) {
      console.error(`No GEX statistics available for day ${selectedDay} and scenario ${vvixScenario}`);
      return;
    }

    // Create normalized data for plotting the main series
    const plotData = sortedData.map((entry, index) => ({
      time: index + 1,  // Sequential index to satisfy LWC time requirement
      value: fillMissingValues(entry, `day${selectedDay}Diff`),  // Use selected day difference
      gexValue: entry.gexValue,  // Keep GEX value for display purposes
    }));


    // Create data for the upper and lower bounds by mapping gexValue to the correct GEX range
    const upperBoundData = sortedData.map((entry, index) => {
      const gexRangeKey = mapGexToRange(entry.gexValue);
      const upperBoundValue = gexRangeKey ? selectedGexStatistics[gexRangeKey]?.adjustedUpperBound : null;
      return {
        time: index + 1,
        value: upperBoundValue,
      };
    }).filter(point => point.value !== undefined && point.value !== null); // Filter out missing values

    const lowerBoundData = sortedData.map((entry, index) => {
      const gexRangeKey = mapGexToRange(entry.gexValue);
      const lowerBoundValue = gexRangeKey ? selectedGexStatistics[gexRangeKey]?.adjustedLowerBound : null;
      return {
        time: index + 1,
        value: lowerBoundValue,
      };
    }).filter(point => point.value !== undefined && point.value !== null); // Filter out missing values

    // Assuming `pairedData` is your array of data
    const mostRecentEntry = pairedData.reduce((latest, entry) => {
      return new Date(entry.date) > new Date(latest.date) ? entry : latest;
    }, pairedData[0]);  // Start by assuming the first entry is the most recent
    // console.log(mostRecentEntry)
    if (!mostRecentEntry) {
      console.error('No most recent GEX data point found.');
      return;
    }

    // Map the most recent GEX value to the appropriate range
    const mostRecentGexRangeKey = mapGexToRange(mostRecentEntry.gexValue);
    // console.log(mostRecentGexRangeKey)


    // Get the corresponding upper and lower bounds for the most recent GEX data
    const mostRecentUpperBound = mostRecentGexRangeKey ? selectedGexStatistics[mostRecentGexRangeKey]?.adjustedUpperBound : null;
    const mostRecentLowerBound = mostRecentGexRangeKey ? selectedGexStatistics[mostRecentGexRangeKey]?.adjustedLowerBound : null;

    // Retrieve the most recent SPX price from spxGexData
    const spxGexData = useStore.getState().spxGexData;
    const mostRecentSPXPrice = spxGexData[0]?.price; // Get the first value as the most recent

    if (mostRecentSPXPrice && mostRecentUpperBound !== null && mostRecentLowerBound !== null) {
      const upperPercent = mostRecentUpperBound.toFixed(2) + '%';
      const lowerPercent = mostRecentLowerBound.toFixed(2) + '%';
      const spxUpper = mostRecentSPXPrice * (1 + mostRecentUpperBound / 100);
      const spxLower = mostRecentSPXPrice * (1 + mostRecentLowerBound / 100);

      // Update state
      setUpperBoundPercent(upperPercent);
      setLowerBoundPercent(lowerPercent);
      setSpxUpperBound(spxUpper.toFixed(2));
      setSpxLowerBound(spxLower.toFixed(2));

      // Log the percentage values along with the SPX index bounds
      console.log(`SPX Index Upper Bound: ${spxUpper.toFixed(2)} (${upperPercent})`);
      console.log(`SPX Index Lower Bound: ${spxLower.toFixed(2)} (${lowerPercent})`);
    } else {
      console.log('No SPX price data or bounds available.');
    }

    // Modify the markers logic to use the most recent entry
    let markers = plotData.map((point, index) => {
      // Compare the date of sortedData to the most recent date from pairedData
      const isMostRecent = sortedData[index].date === mostRecentEntry.date;

      return {
        time: point.time,
        position: 'inBar',
        color: getColorForGexValue(sortedData[index].gexValue),
        shape: 'circle',
        size:  0.5,
      };
    });

    // Plot the most recent data point using the same logic as plotData
    mostRecentSeriesRef.current.setData([{
      time: plotData.find(p => p.gexValue === mostRecentEntry.gexValue)?.time,  // Find the correct time from plotData using gexValue
      value: fillMissingValues(mostRecentEntry, `day${selectedDay}Diff`),  // Y-axis value (price difference)
    }]);

    // Add the marker using the correct time and gexValue
    mostRecentSeriesRef.current.setMarkers([{
      time: plotData.find(p => p.gexValue === mostRecentEntry.gexValue)?.time,  // Find the correct time from plotData
      position: 'inBar',
      // color: 'rgba(0,0,0,0)',
      color: 'hsl(303,100%,50%)',
      shape: 'circle',
      size: 2,  // Larger size for emphasis
    }]);



    seriesRef.current.setMarkers(markers);
    seriesRef.current.setData(plotData);   // Main plot data
    upperBoundRef.current.setData(upperBoundData);   // Upper bound series
    lowerBoundRef.current.setData(lowerBoundData);   // Lower bound series

    chartRef.current.timeScale().fitContent();

    setIsChartInitialized(true);

    const handleResize = () => {
      if (chartRef.current) {
        const newWidth = window.innerWidth * 0.95;
        const newHeight = window.innerHeight * 0.90;
        chartRef.current.resize(newWidth, newHeight);
        chartRef.current.timeScale().fitContent();
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [expectedMoveData, selectedDay]);  // Re-run when expectedMoveData or selectedDay changes

  if (!expectedMoveData || expectedMoveData.length === 0) {
    return <div>Loading data...</div>;
  }

  return (
    <div className="main-flex-container">
      <div className="chart-container">

        {/* Axis labels and dropdown container */}
        <div className="overlay-container">
          {/* Overlay the dropdown on the chart */}
          <div className="label-dropdown-container">
            <label>Select GEM Period: </label>
            <select value={selectedDay} onChange={handleDayChange}>
              <option value={1}>0 DTE GEM</option>
              <option value={2}>1 DTE GEM</option>
              <option value={3}>2 DTE GEM</option>
              <option value={4}>3 DTE GEM</option>
              <option value={5}>4 DTE GEM</option>
            </select>

            {/* Display the upper and lower bounds */}
            <div className="bounds-info">
              <p>Upper Bound: {upperBoundPercent} (SPX: {spxUpperBound})</p>
              <p>Lower Bound: {lowerBoundPercent} (SPX: {spxLowerBound})</p>
            </div>
          </div>

          {/* Axis labels */}
          <div className="axis-labels">
            <div className="y-axis-label">SPX CHANGE (%)</div>
            <div className="x-axis-label">SPX GAMMA EXPOSURE (Billions)</div>
          </div>
        </div>

        {/* Chart rendering area */}
        <div ref={chartContainerRef} className="chart" />
        <div className="overlay">Gamma Expected Move (GEM) Dashboard</div> {/* Overlay for Main Chart */}   
      </div>
    </div>
  );


};

export default GEM_Chart;



    //Color scheme for spectrum color.
    // // Function to map GEX values to colors
    // const getColorForGexValue = (gexValue) => {
    //   const capValue = 9e9; // 9 billion cap
    //   const opacity = 1; // Set semi-transparency for all points
    //
    //   if (gexValue >= 2e9) {
    //     const cappedGexValue = Math.min(gexValue, capValue);
    //     let normalizedGex = (cappedGexValue - 2e9) / (capValue - 2e9);
    //     normalizedGex = Math.pow(normalizedGex, 0.9); // Exponent for smoothness
    //     const hue = 60 + (normalizedGex * 180); // Gradient from yellow to blue
    //     return `hsla(${hue}, 100%, 50%, ${opacity})`; // Apply fixed opacity
    //   } else if (gexValue >= 0) {
    //     let normalizedGex = gexValue / 2e9;
    //     normalizedGex = Math.pow(normalizedGex, 0.7);
    //     const hue = normalizedGex * 60; // Red to yellow gradient
    //     return `hsla(${hue}, 100%, 50%, ${opacity})`; // Apply fixed opacity
    //   } else {
    //     return `hsla(0, 100%, 50%, ${opacity})`; // Pure bright red with fixed opacity
    //   }
    // };






















