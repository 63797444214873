import { useEffect } from 'react';
import useStore from '../Store/gamma_expected_move_data_store';
import { runGammaExpectedMoveCalculation } from './GEM_Calculations';

// const MarketGreeksWssClient = () => {
  // useEffect(() => {
  //   const ws = new WebSocket('ws://localhost:3002');  // WebSocket connection

  //   ws.onopen = () => {
  //     console.log('Connected to WebSocket server');
  //     ws.send(JSON.stringify({ type: 'market-state-data' }));  // Request market data
  //   };

  //Websocket server code for heroku.
const MarketGreeksWssClient = () => {
  useEffect(() => {
    // Access the WebSocket URL from the environment variable
    const wsUrl = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:3002'; // Fallback for local development

    // Initialize WebSocket connection
    const ws = new WebSocket(wsUrl);

    ws.onopen = () => {
      console.log('Connected to WebSocket server');
      // Request market state data
      ws.send(JSON.stringify({ type: 'market-state-data' }));
    };

    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);

      if (response.type === 'market-state-data-response') {
        const { setSpxData, setSpxGexData, setVvixData } = useStore.getState();  // Access Zustand state

        // Helper function to transform date
        const transformDate = (date) => {
          if (typeof date === 'string') {
            return date.split('T')[0];  // Convert string ISO to YYYY-MM-DD
          } else if (typeof date === 'number') {
            return new Date(date * 1000).toISOString().split('T')[0];  // Convert Unix timestamp to YYYY-MM-DD
          } else if (date && date.year && date.month && date.day) {
            return `${date.year}-${String(date.month).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
          } else {
            throw new Error('Invalid date format');
          }
        };

        // Format the date in the data
        const formatDataDate = (data) => {
          return data.map(item => ({
            ...item,
            date: transformDate(item.date),
          }));
        };

        // Sort data in chronological order (descending)
        const sortDataChronologically = (data) => {
          return data.sort((a, b) => new Date(b.date) - new Date(a.date));
        };

        // Format and sort both SPX, SPX GEX, and VVIX data
        const formattedSpxData = sortDataChronologically(formatDataDate(response.spxData));
        const formattedSpxGexData = sortDataChronologically(formatDataDate(response.spxGexData));
        const formattedVvixData = sortDataChronologically(formatDataDate(response.vvixData));

        // Set the data in the Zustand store
        setSpxData(formattedSpxData);
        setSpxGexData(formattedSpxGexData);
        setVvixData(formattedVvixData);  // Adding VVIX data to the Zustand store

        // Trigger the calculation after data is updated
        runGammaExpectedMoveCalculation();  // <== Calculation triggered here
      }
    };

    ws.onclose = () => {
      // console.log('WebSocket connection closed');
    };

    ws.onerror = (error) => {
      // console.error('WebSocket error:', error);
    };

    // Clean up WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, []);

  return null;  // No UI component to render
};

export default MarketGreeksWssClient;








// import { useEffect } from 'react';
// import useStore from '../Store/gamma_expected_move_data_store';
// import { runGammaExpectedMoveCalculation } from './GEM_Calculations';
//
// const MarketGreeksWssClient = () => {
//   useEffect(() => {
//     const ws = new WebSocket('ws://localhost:3002');  // WebSocket connection
//
//     ws.onopen = () => {
//       console.log('Connected to WebSocket server');
//       ws.send(JSON.stringify({ type: 'market-state-data' }));  // Request market data
//     };
//
//     ws.onmessage = (event) => {
//       const response = JSON.parse(event.data);
//
//       if (response.type === 'market-state-data-response') {
//         const { setSpxData, setSpxGexData } = useStore.getState();  // Access Zustand state
//
//         // Helper function to transform date
//         const transformDate = (date) => {
//           if (typeof date === 'string') {
//             return date.split('T')[0];  // Convert string ISO to YYYY-MM-DD
//           } else if (typeof date === 'number') {
//             return new Date(date * 1000).toISOString().split('T')[0];  // Convert Unix timestamp to YYYY-MM-DD
//           } else if (date && date.year && date.month && date.day) {
//             return `${date.year}-${String(date.month).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
//           } else {
//             throw new Error('Invalid date format');
//           }
//         };
//
//         // Format the date in the data
//         const formatDataDate = (data) => {
//           return data.map(item => ({
//             ...item,
//             date: transformDate(item.date),
//           }));
//         };
//
//         // Sort data in chronological order (descending)
//         const sortDataChronologically = (data) => {
//           return data.sort((a, b) => new Date(b.date) - new Date(a.date));
//         };
//
//         // Format and sort both SPX and SPX GEX data
//         const formattedSpxData = sortDataChronologically(formatDataDate(response.spxData));
//         const formattedSpxGexData = sortDataChronologically(formatDataDate(response.spxGexData));
//
//         // Set the data in the Zustand store
//         setSpxData(formattedSpxData);
//         setSpxGexData(formattedSpxGexData);
//
//         // Trigger the calculation after data is updated
//         runGammaExpectedMoveCalculation();  // <== Calculation triggered here
//       }
//     };
//
//     ws.onclose = () => {
//       // console.log('WebSocket connection closed');
//     };
//
//     ws.onerror = (error) => {
//       // console.error('WebSocket error:', error);
//     };
//
//     // Clean up WebSocket connection when the component unmounts
//     return () => {
//       ws.close();
//     };
//   }, []);
//
//   return null;  // No UI component to render
// };
//
// export default MarketGreeksWssClient;



















