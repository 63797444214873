import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import MarketGreeksWssClient from './Components/gamma-expected_move_wss_client';
import GEM_Chart from './Components/GEM_Chart';  // Import the GEM_Chart component
import './App.css';

function App() {

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-3T651JWGNW');
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <div className="app-container">
          <MarketGreeksWssClient /> {/* This will initiate the WebSocket connection */}
          <GEM_Chart /> {/* This will render the chart */}
        </div>
      </header>
    </div>
  );
}

export default App;
