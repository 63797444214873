import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode, LineStyle } from 'lightweight-charts';
import useStore from '../Store/gamma_expected_move_data_store';
import './styles.css';



const GEM_Chart = () => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const seriesRef = useRef(null);
  const upperBoundRef = useRef(null); // Upper bound series
  const lowerBoundRef = useRef(null); // Lower bound series
  const [upperBoundPercent, setUpperBoundPercent] = useState(null);
  const [lowerBoundPercent, setLowerBoundPercent] = useState(null);
  const [spxUpperBound, setSpxUpperBound] = useState(null);
  const [spxLowerBound, setSpxLowerBound] = useState(null);


  // Access the expectedMoveData and GEX statistics from Zustand
  const expectedMoveData = useStore((state) => state.expectedMoveData);
  const vvixData = useStore((state) => state.vvixData); // For determining the VVIX scenario
  // Add the rest for days 2-5 as needed

  const [selectedDay, setSelectedDay] = useState(1); // Default to 1-day expected move
  const [isChartInitialized, setIsChartInitialized] = useState(false);

  // Handle day change for the dropdown
  // Handle day change for the dropdown
  const handleDayChange = (event) => {
    setSelectedDay(parseInt(event.target.value)); // Update the selected day range
  };

  // Function to map GEX value to the correct GEX range key
  const mapGexToRange = (gexValue) => {
    if (gexValue < -2e9) return 'Negative (< -2B)';
    if (gexValue >= -2e9 && gexValue < -1e9) return '-2B to -1B';
    if (gexValue >= -1e9 && gexValue < 0) return '-1B to 0B';
    if (gexValue >= 0 && gexValue < 1e9) return '0B to 1B';
    if (gexValue >= 1e9 && gexValue < 2e9) return '1B to 2B';
    if (gexValue >= 2e9 && gexValue < 3e9) return '2B to 3B';
    if (gexValue >= 3e9 && gexValue < 4e9) return '3B to 4B';
    if (gexValue >= 4e9 && gexValue < 5e9) return '4B to 5B';
    if (gexValue >= 5e9 && gexValue < 6e9) return '5B to 6B';
    if (gexValue >= 6e9 && gexValue < 7e9) return '6B to 7B';
    if (gexValue >= 7e9) return '7B+';
    return null; // Fallback in case of invalid gexValue
  };

  useEffect(() => {
    if (!expectedMoveData || expectedMoveData.length === 0) {
      console.log('No expected move data available for plotting.');
      return;
    }

    if (!chartContainerRef.current) {
      console.error('No chart container available.');
      return;
    }

    if (!chartRef.current && chartContainerRef.current) {
      // Initialize the chart
      chartRef.current = createChart(chartContainerRef.current, {
        width: window.innerWidth * 0.95,
        height: window.innerHeight * 0.90,
        timeScale: {
          visible: true,
          fixRightEdge: true,
          fixLeftEdge: true,

          tickMarkFormatter: (index) => {
            const gexValue = sortedData.find((d, i) => i === index)?.gexValue || index;
            if (gexValue >= 1e9 || gexValue <= -1e9) {
              return (Math.floor(gexValue / 1e9)) + 'B';
            } else if (gexValue < 1e9 && gexValue > 0) {
              return '0B';
            } else if (gexValue < 0 && gexValue > -1e9) {
              return '-0B';
            }
            return gexValue.toString();
          },
        },
        layout: {
          background: { type: 'solid', color: '#000000' },
          textColor: '#d1d4dc',
        },
        grid: {
          vertLines: { color: 'rgba(42, 46, 57, 0)' },
          horzLines: { color: 'rgba(87, 87, 87, 0.23)' },
        },
        crosshair: {
          mode: CrosshairMode.Normal,
          vertLine: {
            width: 8,
            color: '#C3BCDB44',
            style: LineStyle.Solid,
            labelBackgroundColor: '#B18800',
          },
          horzLine: {
            color: '#C3BCDB44',
            labelBackgroundColor: '#B18800',
          },
        },
        rightPriceScale: {
          scaleMargins: { top: 0.2, bottom: 0.2 },
        },
      });

      // Create a line series that will simulate a scatter plot
      seriesRef.current = chartRef.current.addLineSeries({
        color: 'rgba(0,0,0,0)',
        lineWidth: 2,
        crossHairMarkerVisible: true,
        lastValueVisible: false,
        priceLineVisible: false,
      });

      // Create line series for upper and lower bounds
      upperBoundRef.current = chartRef.current.addLineSeries({
        color: 'rgb(255,255,255)', // Red for upper bound
        lineWidth: 2,
        lastValueVisible: false,       // Hides the last value label
        priceLineVisible: false,       // Hides the horizontal price line
      });

      lowerBoundRef.current = chartRef.current.addLineSeries({
        color: 'rgb(255,255,255)', // Blue for lower bound
        lineWidth: 2,
        lastValueVisible: false,       // Hides the last value label
        priceLineVisible: false,       // Hides the horizontal price line
      });
    }

    const getColorForGexValue = (gexValue) => {
      const capValue = 9e9; // Cap at 9 billion
      const opacity = 1; // Full opacity for vibrant colors

      if (gexValue >= 0) {
        let normalizedGex = Math.min(gexValue / capValue, 1); // Normalize between 0 and 1
        const hue = 0 + (normalizedGex * 180); // Gradual transition from red (0) to cyan-blue (180 degrees)
        const lightness = 55 + (normalizedGex * 5); // Adjust lightness for more intense colors, capped at 60%
        return `hsla(${hue}, 100%, ${lightness}%, ${opacity})`;

      } else {
        let normalizedGex = Math.min(Math.abs(gexValue) / 1e9, 1); // Normalize for negative values
        const hue = 0; // Pure red
        const lightness = 50 - (normalizedGex * 15); // Darken the red for more negative values
        return `hsla(${hue}, 100%, ${lightness}%, ${opacity})`;
      }
    };

    const fillMissingValues = (entry, dayKey) => {
      return entry[dayKey] !== null && entry[dayKey] !== undefined
        ? entry[dayKey]
        : 0; // Default to 0 if value is missing
    };

    const sortedData = expectedMoveData.slice().sort((a, b) => a.gexValue - b.gexValue);

    // Determine the VVIX scenario (above or below)
    const vvixScenario = vvixData[0].vvix_avg_z_score < vvixData[0].vvix_super_smooth_z_score
      ? 'vvixBelow'
      : 'vvixAbove';

    // Choose GEX statistics based on the selected VVIX scenario and day
    const selectedGexStatistics = useStore.getState()[`gexStatisticsDay${selectedDay}_${vvixScenario}`];

    // Add logging to see if we are getting the correct data
    console.log('Selected GEX statistics:', selectedGexStatistics);

    if (!selectedGexStatistics || Object.keys(selectedGexStatistics).length === 0) {
      console.error(`No GEX statistics available for day ${selectedDay} and scenario ${vvixScenario}`);
      return;
    }
    // Create normalized data for plotting the main series
    const plotData = sortedData.map((entry, index) => ({
      time: index + 1,  // Sequential index to satisfy LWC time requirement
      value: fillMissingValues(entry, `day${selectedDay}Diff`),  // Use selected day difference
      gexValue: entry.gexValue,  // Keep GEX value for display purposes
    }));

    // Create data for the upper and lower bounds by mapping gexValue to the correct GEX range
    const upperBoundData = sortedData.map((entry, index) => {
      const gexRangeKey = mapGexToRange(entry.gexValue);
      const upperBoundValue = gexRangeKey ? selectedGexStatistics[gexRangeKey]?.adjustedUpperBound : null;
      return {
        time: index + 1,
        value: upperBoundValue,
      };
    }).filter(point => point.value !== undefined && point.value !== null); // Filter out missing values

    const lowerBoundData = sortedData.map((entry, index) => {
      const gexRangeKey = mapGexToRange(entry.gexValue);
      const lowerBoundValue = gexRangeKey ? selectedGexStatistics[gexRangeKey]?.adjustedLowerBound : null;
      return {
        time: index + 1,
        value: lowerBoundValue,
      };
    }).filter(point => point.value !== undefined && point.value !== null); // Filter out missing values


    // Find the most recent data point in the sorted data
    const mostRecentIndex = sortedData.length - 1;

    // Get the corresponding upper and lower bound for the most recent data
    const mostRecentUpperBound = upperBoundData.find((dataPoint) => dataPoint.time === mostRecentIndex + 1)?.value;
    const mostRecentLowerBound = lowerBoundData.find((dataPoint) => dataPoint.time === mostRecentIndex + 1)?.value;

    // Retrieve the most recent SPX price from spxGexData
    const spxGexData = useStore.getState().spxGexData;
    const mostRecentSPXPrice = spxGexData[0]?.price; // Get the first value as the most recent


    if (mostRecentSPXPrice) {
      const upperPercent = mostRecentUpperBound.toFixed(2) + '%';
      const lowerPercent = mostRecentLowerBound.toFixed(2) + '%';
      const spxUpper = mostRecentSPXPrice * (1 + mostRecentUpperBound / 100);
      const spxLower = mostRecentSPXPrice * (1 + mostRecentLowerBound / 100);

      // Update state
      setUpperBoundPercent(upperPercent);
      setLowerBoundPercent(lowerPercent);
      setSpxUpperBound(spxUpper.toFixed(2));
      setSpxLowerBound(spxLower.toFixed(2));

      // Log the percentage values along with the SPX index bounds
      console.log(`SPX Index Upper Bound: ${spxUpper.toFixed(2)} (${upperPercent})`);
      console.log(`SPX Index Lower Bound: ${spxLower.toFixed(2)} (${lowerPercent})`);
    } else {
      console.log('No SPX price data available.');
    }

    // if (mostRecentSPXPrice) {
    //   // Log the percentage values before applying them to the SPX price
    //   console.log('Most Recent Upper Bound Percentage:', mostRecentUpperBound.toFixed(2) + '%');
    //   console.log('Most Recent Lower Bound Percentage:', mostRecentLowerBound.toFixed(2) + '%');
    //
    //   // Calculate the upper and lower bounds in terms of the SPX index
    //   let spxUpperBound = mostRecentSPXPrice * (1 + mostRecentUpperBound / 100);
    //   let spxLowerBound = mostRecentSPXPrice * (1 + mostRecentLowerBound / 100);
    //
    //   // Log the SPX index-adjusted bounds with two decimal places
    //   console.log('SPX Index Upper Bound:', spxUpperBound.toFixed(2));
    //   console.log('SPX Index Lower Bound:', spxLowerBound.toFixed(2));
    // } else {
    //   console.log('No SPX price data available.');
    // }

    let markers = plotData.map((point, index) => {
      const isMostRecent = sortedData[index].isMostRecent;
      return {
        time: point.time,
        position: 'inBar',
        color: isMostRecent ? 'hsl(0,0%,100%)' : getColorForGexValue(sortedData[index].gexValue),
        shape: 'circle',
        size: isMostRecent ? 10 : .5,
      };
    });

    seriesRef.current.setMarkers(markers);
    seriesRef.current.setData(plotData);   // Main plot data
    upperBoundRef.current.setData(upperBoundData);   // Upper bound series
    lowerBoundRef.current.setData(lowerBoundData);   // Lower bound series

    chartRef.current.timeScale().fitContent();

    setIsChartInitialized(true);

    const handleResize = () => {
      if (chartRef.current) {
        const newWidth = window.innerWidth * 0.95;
        const newHeight = window.innerHeight * 0.90;
        chartRef.current.resize(newWidth, newHeight);
        chartRef.current.timeScale().fitContent();
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [expectedMoveData, selectedDay]);  // Re-run when expectedMoveData or selectedDay changes

  if (!expectedMoveData || expectedMoveData.length === 0) {
    return <div>Loading data...</div>;
  }

  return (
    <div className="main-flex-container">
      <div className="chart-container">

        {/* Axis labels and dropdown container */}
        <div className="overlay-container">
          {/* Overlay the dropdown on the chart */}
          <div className="label-dropdown-container">
            <label>Select GEM Period: </label>
            <select value={selectedDay} onChange={handleDayChange}>
              <option value={1}>1 Day GEM</option>
              <option value={2}>2 Day GEM</option>
              <option value={3}>3 Day GEM</option>
              <option value={4}>4 Day GEM</option>
              <option value={5}>5 Day GEM</option>
            </select>

            {/* Display the upper and lower bounds */}
            <div className="bounds-info">
              <p>Upper Bound: {upperBoundPercent} (SPX: {spxUpperBound})</p>
              <p>Lower Bound: {lowerBoundPercent} (SPX: {spxLowerBound})</p>
            </div>
          </div>

          {/* Axis labels */}
          <div className="axis-labels">
            <div className="y-axis-label">SPX CHANGE (%)</div>
            <div className="x-axis-label">SPX GAMMA EXPOSURE (Billions)</div>
          </div>
        </div>

        {/* Chart rendering area */}
        <div ref={chartContainerRef} className="chart" />
      </div>
    </div>
  );


};

export default GEM_Chart;



    //Color scheme for spectrum color.
    // // Function to map GEX values to colors
    // const getColorForGexValue = (gexValue) => {
    //   const capValue = 9e9; // 9 billion cap
    //   const opacity = 1; // Set semi-transparency for all points
    //
    //   if (gexValue >= 2e9) {
    //     const cappedGexValue = Math.min(gexValue, capValue);
    //     let normalizedGex = (cappedGexValue - 2e9) / (capValue - 2e9);
    //     normalizedGex = Math.pow(normalizedGex, 0.9); // Exponent for smoothness
    //     const hue = 60 + (normalizedGex * 180); // Gradient from yellow to blue
    //     return `hsla(${hue}, 100%, 50%, ${opacity})`; // Apply fixed opacity
    //   } else if (gexValue >= 0) {
    //     let normalizedGex = gexValue / 2e9;
    //     normalizedGex = Math.pow(normalizedGex, 0.7);
    //     const hue = normalizedGex * 60; // Red to yellow gradient
    //     return `hsla(${hue}, 100%, 50%, ${opacity})`; // Apply fixed opacity
    //   } else {
    //     return `hsla(0, 100%, 50%, ${opacity})`; // Pure bright red with fixed opacity
    //   }
    // };






// //Working code that plot
// import React, { useEffect, useRef, useState } from 'react';
// import { createChart, CrosshairMode, LineStyle } from 'lightweight-charts';
// import useStore from '../Store/gamma_expected_move_data_store';
// import './styles.css';
//
// const GEM_Chart = () => {
//   const chartContainerRef = useRef(null);
//   const chartRef = useRef(null);
//   const seriesRef = useRef(null);
//
//   // Access the expectedMoveData from Zustand
//   const expectedMoveData = useStore((state) => state.expectedMoveData);
//   const [isChartInitialized, setIsChartInitialized] = useState(false);
//
//   useEffect(() => {
//     if (!expectedMoveData || expectedMoveData.length === 0) {
//       console.log('No expected move data available for plotting.');
//       return;
//     }
//
//     if (!chartContainerRef.current) {
//       console.error('No chart container available.');
//       return;
//     }
//
//     if (!chartRef.current && chartContainerRef.current) {
//       // Initialize the chart
//       chartRef.current = createChart(chartContainerRef.current, {
//         width: window.innerWidth * 0.95,
//         height: window.innerHeight * 0.90,
//         timeScale: {
//           visible: true,
//           fixRightEdge: true,
//           fixLeftEdge: true,
//           rightOffset: 20,
//           leftOffset: 20,
//           tickMarkFormatter: (index) => {
//             // Find the GEX value for the current index
//             const gexValue = sortedData.find((d, i) => i === index)?.gexValue || index;
//             if (gexValue >= 1e9 || gexValue <= -1e9) {
//               return (Math.floor(gexValue / 1e9)) + 'B';
//             } else if (gexValue < 1e9 && gexValue > 0) {
//               return '0B';
//             } else if (gexValue < 0 && gexValue > -1e9) {
//               return '-0B';
//             }
//             return gexValue.toString();
//           },
//         },
//         layout: {
//           background: { type: 'solid', color: '#000000' },
//           textColor: '#d1d4dc',
//         },
//         grid: {
//           vertLines: { color: 'rgba(42, 46, 57, 0)' },
//           horzLines: { color: 'rgba(87, 87, 87, 0.23)' },
//         },
//         crosshair: {
//           mode: CrosshairMode.Normal,
//           vertLine: {
//             width: 8,
//             color: '#C3BCDB44',
//             style: LineStyle.Solid,
//             labelBackgroundColor: '#B18800',
//           },
//           horzLine: {
//             color: '#C3BCDB44',
//             labelBackgroundColor: '#B18800',
//           },
//         },
//         rightPriceScale: {
//           scaleMargins: { top: 0.2, bottom: 0.2 },
//         },
//       });
//
//       // Create a line series that will simulate a scatter plot
//       seriesRef.current = chartRef.current.addLineSeries({
//         color: 'rgba(0,0,0,0)',
//         lineWidth: 2,
//         crossHairMarkerVisible: true,
//         lastValueVisible: false,
//         priceLineVisible: false,
//       });
//     }
//
//     // Function to map GEX values to colors
//     const getColorForGexValue = (gexValue) => {
//       const capValue = 9e9; // 9 billion cap
//       const minGex = Math.min(...sortedData.map(d => d.gexValue));
//       const opacity = 0.5; // Set semi-transparency for all points
//
//       if (gexValue >= 2e9) {
//         const cappedGexValue = Math.min(gexValue, capValue);
//         let normalizedGex = (cappedGexValue - 2e9) / (capValue - 2e9);
//         normalizedGex = Math.pow(normalizedGex, 0.9); // Exponent for smoothness
//         const hue = 60 + (normalizedGex * 180); // Gradient from yellow to blue
//         return `hsla(${hue}, 100%, 50%, ${opacity})`; // Apply fixed opacity
//
//       } else if (gexValue >= 0) {
//         let normalizedGex = gexValue / 2e9;
//         normalizedGex = Math.pow(normalizedGex, 0.7);
//         const hue = normalizedGex * 60; // Red to yellow gradient
//         return `hsla(${hue}, 100%, 50%, ${opacity})`; // Apply fixed opacity
//
//       } else {
//         return `hsla(0, 100%, 50%, ${opacity})`; // Pure bright red with fixed opacity
//       }
//     };
//
//
//
//     const sortedData = expectedMoveData.slice().sort((a, b) => a.gexValue - b.gexValue);
//
//     // Create normalized data for plotting, using a sequential index for the time field
//     const plotData = sortedData.map((entry, index) => ({
//       time: index + 1,  // Sequential index to satisfy LWC time requirement
//       value: entry.priceDifferencePercent,  // Use priceDifferencePercent for Y-axis
//       gexValue: entry.gexValue,  // Keep GEX value for display purposes
//     }));
//
//     // Create markers for the plot data, distinguishing the most recent point and others
//     let markers = plotData.map((point, index) => {
//       const isMostRecent = sortedData[index].isMostRecent;
//       return {
//         time: point.time,  // Use the sequential time index
//         position: 'inBar',
//         color: isMostRecent ? 'hsl(0,0%,100%)' : getColorForGexValue(sortedData[index].gexValue),
//         shape: isMostRecent ? 'circle' : 'circle',  // Use triangle for the most recent, circle for others
//         size: isMostRecent ? 10 : 1,  // Larger marker for the most recent day
//       };
//     });
//
//      seriesRef.current.setMarkers(markers);
//     // Set the main plot data and markers on the chart
//     seriesRef.current.setData(plotData);
//
//
//     chartRef.current.timeScale().fitContent();
//
//     setIsChartInitialized(true);
//
//     const handleResize = () => {
//       if (chartRef.current) {
//         const newWidth = window.innerWidth * 0.95;
//         const newHeight = window.innerHeight * 0.90;
//         chartRef.current.resize(newWidth, newHeight);
//         chartRef.current.timeScale().fitContent();
//       }
//     };
//
//     window.addEventListener('resize', handleResize);
//     handleResize();
//
//     return () => {
//       window.removeEventListener('resize', handleResize);
//       if (chartRef.current) {
//         chartRef.current.remove();
//         chartRef.current = null;
//       }
//     };
//   }, [expectedMoveData]);
//
//   if (!expectedMoveData || expectedMoveData.length === 0) {
//     return <div>Loading data...</div>;
//   }
//
//   return (
//     <div className="main-flex-container">
//       <div className="chart-container" style={{ position: 'relative' }}>
//         <div ref={chartContainerRef} className="chart" />
//       </div>
//     </div>
//   );
// };
//
// export default GEM_Chart;
































//
// import React, { useEffect, useRef, useState } from 'react';
// import { createChart, CrosshairMode, LineStyle } from 'lightweight-charts';
// import useStore from '../Store/gamma_expected_move_data_store';
// import './styles.css'; // Import your CSS file
//
// const GEM_Chart = () => {
//   const chartContainerRef = useRef(null);
//   const chartRef = useRef(null);
//   const seriesRef = useRef(null);
//
//   // Access the expectedMoveDataNoFilter for day1 from Zustand
//   const expectedMoveDataDay1 = useStore((state) => state.expectedMoveDataNoFilter.day1);
//   const [isChartInitialized, setIsChartInitialized] = useState(false);
//
//   useEffect(() => {
//     // Check if data is available before rendering the chart
//     if (!expectedMoveDataDay1 || expectedMoveDataDay1.length === 0) {
//       console.log('No expected move data available for plotting.');
//       return;  // Don't proceed until data is available
//     }
//
//     if (!chartContainerRef.current) {
//       console.error('No chart container available.');
//       return;
//     }
//
//     if (!chartRef.current && chartContainerRef.current) {
//       // Initialize the chart
//       chartRef.current = createChart(chartContainerRef.current, {
//         width: window.innerWidth * 0.95,
//         height: window.innerHeight * 0.90,
//         timeScale: {
//           visible: true,
//           fixRightEdge: true,
//           fixLeftEdge: true,
//           rightOffset: 20,
//           leftOffset: 20,
//           tickMarkFormatter: (gexValue) => {
//             // Handle positive and negative values
//             if (gexValue >= 1e9 || gexValue <= -1e9) {
//               return (Math.floor(gexValue / 1e9)) + 'B';
//             } else if (gexValue < 1e9 && gexValue > 0) {
//               return '0B';
//             } else if (gexValue < 0 && gexValue > -1e9) {
//               return '-0B';
//             }
//             return gexValue.toString();
//           },
//         },
//         layout: {
//           background: { type: 'solid', color: '#000000' },
//           textColor: '#d1d4dc',
//         },
//         grid: {
//           vertLines: { color: 'rgba(42, 46, 57, 0)' },
//           horzLines: { color: 'rgba(87, 87, 87, 0.23)' },
//         },
//         crosshair: {
//           mode: CrosshairMode.Normal,
//           vertLine: {
//             width: 8,
//             color: '#C3BCDB44',
//             style: LineStyle.Solid,
//             labelBackgroundColor: '#B18800',
//           },
//           horzLine: {
//             color: '#C3BCDB44',
//             labelBackgroundColor: '#B18800',
//           },
//         },
//         rightPriceScale: {
//           scaleMargins: { top: 0.2, bottom: 0.2 },
//         },
//       });
//
//       // Create a line series that will simulate a scatter plot
//       seriesRef.current = chartRef.current.addLineSeries({
//         color: 'rgba(0,0,0,0)',
//         lineWidth: 2,
//         crossHairMarkerVisible: true,
//         lastValueVisible: false,
//         priceLineVisible: false,
//       });
//     }
//
//     // Function to map GEX values to colors
//     const getColorForGexValue = (gexValue) => {
//       const capValue = 9e9; // 9 billion cap
//       const minGex = Math.min(...sortedData.map(d => d.gexValue));
//
//       if (gexValue >= 2e9) {
//         const cappedGexValue = Math.min(gexValue, capValue);
//         let normalizedGex = (cappedGexValue - 2e9) / (capValue - 2e9);
//         normalizedGex = Math.pow(normalizedGex, 0.9);
//
//         const hue = 60 + (normalizedGex * 180); // Yellow to blue
//         return `hsl(${hue}, 100%, 50%)`;
//
//       } else if (gexValue >= 0) {
//         let normalizedGex = gexValue / 2e9;
//         normalizedGex = Math.pow(normalizedGex, 0.7);
//
//         const hue = normalizedGex * 60; // Red to yellow
//         return `hsl(${hue}, 100%, 50%)`;
//
//       } else {
//         return `hsl(0, 100%, 50%)`; // Pure bright red
//       }
//     };
//
//     // Sort and prepare the data
//     const sortedData = expectedMoveDataDay1.slice().sort((a, b) => a.gexValue - b.gexValue);
//     const plotData = sortedData.map((entry) => ({
//       time: entry.gexValue,
//       value: entry.priceDifferencePercent,
//     }));
//
//     // Add markers to simulate scatter plot with gradient color based on GEX value
//     const markers = plotData.map((point, index) => ({
//       time: point.time,
//       position: 'aboveBar',
//       color: getColorForGexValue(sortedData[index].gexValue),
//       shape: 'circle',
//       size: 1,
//     }));
//
//     seriesRef.current.setData(plotData);
//     seriesRef.current.setMarkers(markers);
//
//     chartRef.current.timeScale().fitContent();
//
//     setIsChartInitialized(true);
//
//     const handleResize = () => {
//       if (chartRef.current) {
//         const newWidth = window.innerWidth * 0.95;
//         const newHeight = window.innerHeight * 0.90;
//         chartRef.current.resize(newWidth, newHeight);
//
//         // Adjust the time scale to fit the new width
//         chartRef.current.timeScale().fitContent();
//       }
//     };
//
//     window.addEventListener('resize', handleResize);
//     handleResize();
//
//     return () => {
//       window.removeEventListener('resize', handleResize);
//       if (chartRef.current) {
//         chartRef.current.remove();
//         chartRef.current = null;
//       }
//     };
//   }, [expectedMoveDataDay1]);
//
//   if (!expectedMoveDataDay1 || expectedMoveDataDay1.length === 0) {
//     return <div>Loading data...</div>;
//   }
//
//   return (
//     <div className="main-flex-container">
//       <div className="chart-container" style={{ position: 'relative' }}>
//         <div ref={chartContainerRef} className="chart"  />
//       </div>
//     </div>
//   );
// };
//
// export default GEM_Chart;






















