const useStore = require('../Store/gamma_expected_move_data_store');

// Helper function to calculate mean
const calculateMean = (values) => {
  const sum = values.reduce((acc, val) => acc + val, 0);
  return sum / values.length;
};

// Helper function to calculate standard deviation
const calculateStandardDeviation = (values, mean) => {
  const variance = values.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / values.length;
  return Math.sqrt(variance);
};

// Helper function to calculate skewness
const calculateSkewness = (data, mean, stdDev) => {
  const n = data.length;
  const cubedDiffs = data.map(value => Math.pow(value - mean, 3));
  const sumCubedDiffs = cubedDiffs.reduce((acc, value) => acc + value, 0);
  const skewness = (n / ((n - 1) * (n - 2))) * (sumCubedDiffs / Math.pow(stdDev, 3));
  return skewness;
};

// Helper function to calculate kurtosis
const calculateKurtosis = (data, mean, stdDev) => {
  const n = data.length;
  const fourthPowerDiffs = data.map(value => Math.pow(value - mean, 4));
  const sumFourthPowerDiffs = fourthPowerDiffs.reduce((acc, value) => acc + value, 0);
  const kurtosis = (n * (n + 1)) / ((n - 1) * (n - 2) * (n - 3)) * (sumFourthPowerDiffs / Math.pow(stdDev, 4)) -
    (3 * Math.pow(n - 1, 2)) / ((n - 2) * (n - 3));
  return kurtosis;
};

// Function to adjust standard deviation bounds based on skewness and kurtosis
const adjustBoundsForSkewnessKurtosis = (mean, stdDev, skewness, kurtosis) => {
  const skewnessFactor = 1 + Math.abs(skewness) * 0.1;  // Adjust skewness sensitivity
  const kurtosisFactor = 1 + Math.abs(kurtosis) * 0.03; // Adjust kurtosis sensitivity

  let upperBound = mean + stdDev;
  let lowerBound = mean - stdDev;

  if (skewness > 0) {
    upperBound = mean + skewnessFactor * stdDev;
  } else if (skewness < 0) {
    lowerBound = mean - skewnessFactor * stdDev;
  }

  if (kurtosis > 0) {
    upperBound *= kurtosisFactor;
    lowerBound *= kurtosisFactor;
  } else if (kurtosis < 0) {
    upperBound /= kurtosisFactor;
    lowerBound /= kurtosisFactor;
  }

  return { upperBound, lowerBound };
};

// Function to calculate GEX statistics for a given day range (day 1 to day 5)
const calculateGexStatisticsForDays = (pairedData, dayRange) => {
  const intervals = {
    'Negative (< -2B)': [],
    '-2B to -1B': [],
    '-1B to 0B': [],
    '0B to 1B': [],
    '1B to 2B': [],
    '2B to 3B': [],
    '3B to 4B': [],
    '4B to 5B': [],
    '5B to 6B': [],
    '6B to 7B': [],
    '7B+': []
  };

  pairedData.forEach(({ gexValue, day1Diff, day2Diff, day3Diff, day4Diff, day5Diff }) => {
    const priceDifferencePercentByDay = [day1Diff, day2Diff, day3Diff, day4Diff, day5Diff];
    const priceDiff = priceDifferencePercentByDay[dayRange - 1];

    if (gexValue < -2e9) {
      intervals['Negative (< -2B)'].push(priceDiff);
    } else if (gexValue >= -2e9 && gexValue < -1e9) {
      intervals['-2B to -1B'].push(priceDiff);
    } else if (gexValue >= -1e9 && gexValue < 0) {
      intervals['-1B to 0B'].push(priceDiff);
    } else if (gexValue >= 0 && gexValue < 1e9) {
      intervals['0B to 1B'].push(priceDiff);
    } else if (gexValue >= 1e9 && gexValue < 2e9) {
      intervals['1B to 2B'].push(priceDiff);
    } else if (gexValue >= 2e9 && gexValue < 3e9) {
      intervals['2B to 3B'].push(priceDiff);
    } else if (gexValue >= 3e9 && gexValue < 4e9) {
      intervals['3B to 4B'].push(priceDiff);
    } else if (gexValue >= 4e9 && gexValue < 5e9) {
      intervals['4B to 5B'].push(priceDiff);
    } else if (gexValue >= 5e9 && gexValue < 6e9) {
      intervals['5B to 6B'].push(priceDiff);
    } else if (gexValue >= 6e9 && gexValue < 7e9) {
      intervals['6B to 7B'].push(priceDiff);
    } else if (gexValue >= 7e9) {
      intervals['7B+'].push(priceDiff);
    }
  });

  // Calculate statistics for each interval, adjusting based on skewness and kurtosis
  const gexStatistics = {};

  Object.keys(intervals).forEach((interval) => {
    const values = intervals[interval];
    if (values.length > 0) {
      const mean = calculateMean(values);
      const stdDev = calculateStandardDeviation(values, mean);
      const skewness = calculateSkewness(values, mean, stdDev);
      const kurtosis = calculateKurtosis(values, mean, stdDev);

      // Adjust standard deviation bounds based on skewness and kurtosis
      const { upperBound, lowerBound } = adjustBoundsForSkewnessKurtosis(mean, stdDev, skewness, kurtosis);

      gexStatistics[interval] = {
        mean,
        stdDev1: stdDev,
        stdDev2: stdDev * 2,
        skewness,
        kurtosis,
        adjustedUpperBound: upperBound,
        adjustedLowerBound: lowerBound,
      };
    } else {
      gexStatistics[interval] = {
        mean: null,
        stdDev1: null,
        stdDev2: null,
        skewness: null,
        kurtosis: null,
        adjustedUpperBound: null,
        adjustedLowerBound: null,
      };
    }
  });

  return gexStatistics;
};

// Function to calculate GEX value and price changes for each day (1 to 5)
const calculateGexAndPriceChange = (spxGexData) => {
  const filteredData = spxGexData.filter((row) => row.gex && row.price);

  const pairedData = filteredData.map((row, index, data) => {
    const gexValue = parseFloat(row.gex);
    const priceToday = parseFloat(row.price);

    const getPrevDayDiff = (daysBack) => {
      if (index - daysBack >= 0) {
        const pricePrevDay = parseFloat(data[index - daysBack].price);
        return ((priceToday - pricePrevDay) / pricePrevDay) * 100;
      }
      return null;
    };

    const day1Diff = getPrevDayDiff(1);
    const day2Diff = getPrevDayDiff(2);
    const day3Diff = getPrevDayDiff(3);
    const day4Diff = getPrevDayDiff(4);
    const day5Diff = getPrevDayDiff(5);

    return {
      date: row.date,
      gexValue,
      day1Diff: day1Diff !== null ? day1Diff : 0,
      day2Diff: day2Diff !== null ? day2Diff : 0,
      day3Diff: day3Diff !== null ? day3Diff : 0,
      day4Diff: day4Diff !== null ? day4Diff : 0,
      day5Diff: day5Diff !== null ? day5Diff : 0,
    };
  });

  if (pairedData.length > 0) {
    const mostRecentEntry = pairedData[pairedData.length - 1];
    mostRecentEntry.day1Diff = 0;
    mostRecentEntry.day2Diff = 0;
    mostRecentEntry.day3Diff = 0;
    mostRecentEntry.day4Diff = 0;
    mostRecentEntry.day5Diff = 0;
    mostRecentEntry.isMostRecent = true;
  }

  return pairedData.filter(item => item !== null);
};

// Function to calculate the GEX statistics for both VVIX conditions
const runGammaExpectedMoveCalculation = () => {
  const store = useStore.getState();
  const spxGexData = store.spxGexData;
  const vvixData = store.vvixData;

  if (!spxGexData || spxGexData.length === 0) {
    console.log('No SPX GEX data available in the store.');
    return;
  }

  // Filter the data for VVIX conditions
  const pairedData = calculateGexAndPriceChange(spxGexData);

  const vvixBelowFilter = pairedData.filter((dataPoint, index) => {
    const vvixPoint = vvixData[index];
    return vvixPoint && vvixPoint.vvix_avg_z_score < vvixPoint.vvix_super_smooth_z_score;
  });

  const vvixAboveFilter = pairedData.filter((dataPoint, index) => {
    const vvixPoint = vvixData[index];
    return vvixPoint && vvixPoint.vvix_avg_z_score > vvixPoint.vvix_super_smooth_z_score;
  });

  // Calculate statistics for VVIX below
  const gexStatisticsDay1_vvixBelow = calculateGexStatisticsForDays(vvixBelowFilter, 1);
  const gexStatisticsDay2_vvixBelow = calculateGexStatisticsForDays(vvixBelowFilter, 2);
  const gexStatisticsDay3_vvixBelow = calculateGexStatisticsForDays(vvixBelowFilter, 3);
  const gexStatisticsDay4_vvixBelow = calculateGexStatisticsForDays(vvixBelowFilter, 4);
  const gexStatisticsDay5_vvixBelow = calculateGexStatisticsForDays(vvixBelowFilter, 5);

  // Calculate statistics for VVIX above
  const gexStatisticsDay1_vvixAbove = calculateGexStatisticsForDays(vvixAboveFilter, 1);
  const gexStatisticsDay2_vvixAbove = calculateGexStatisticsForDays(vvixAboveFilter, 2);
  const gexStatisticsDay3_vvixAbove = calculateGexStatisticsForDays(vvixAboveFilter, 3);
  const gexStatisticsDay4_vvixAbove = calculateGexStatisticsForDays(vvixAboveFilter, 4);
  const gexStatisticsDay5_vvixAbove = calculateGexStatisticsForDays(vvixAboveFilter, 5);

  // // Update the Zustand store with all the statistics and log the data to the console
  // console.log('Paired Data:', pairedData);
  //
  // console.log('VVIX Below - GEX Statistics for Day 1:', gexStatisticsDay1_vvixBelow);
  // console.log('VVIX Below - GEX Statistics for Day 2:', gexStatisticsDay2_vvixBelow);
  // console.log('VVIX Below - GEX Statistics for Day 3:', gexStatisticsDay3_vvixBelow);
  // console.log('VVIX Below - GEX Statistics for Day 4:', gexStatisticsDay4_vvixBelow);
  // console.log('VVIX Below - GEX Statistics for Day 5:', gexStatisticsDay5_vvixBelow);
  //
  // console.log('VVIX Above - GEX Statistics for Day 1:', gexStatisticsDay1_vvixAbove);
  // console.log('VVIX Above - GEX Statistics for Day 2:', gexStatisticsDay2_vvixAbove);
  // console.log('VVIX Above - GEX Statistics for Day 3:', gexStatisticsDay3_vvixAbove);
  // console.log('VVIX Above - GEX Statistics for Day 4:', gexStatisticsDay4_vvixAbove);
  // console.log('VVIX Above - GEX Statistics for Day 5:', gexStatisticsDay5_vvixAbove);

  // Update the Zustand store with all the statistics
  useStore.setState({
    expectedMoveData: pairedData,
    gexStatisticsDay1_vvixBelow,
    gexStatisticsDay2_vvixBelow,
    gexStatisticsDay3_vvixBelow,
    gexStatisticsDay4_vvixBelow,
    gexStatisticsDay5_vvixBelow,
    gexStatisticsDay1_vvixAbove,
    gexStatisticsDay2_vvixAbove,
    gexStatisticsDay3_vvixAbove,
    gexStatisticsDay4_vvixAbove,
    gexStatisticsDay5_vvixAbove,
  });
};

module.exports = {
  runGammaExpectedMoveCalculation,
};






// //Most basic working code.
// const useStore = require('../Store/gamma_expected_move_data_store');
//
//
//
//
//
// // Function to calculate GEX value for the current day and the % price change from the previous 1-5 days
// const calculateGexAndPriceChange = (spxGexData) => {
//   const filteredData = spxGexData.filter((row) => row.gex && row.price);
//
//   const pairedData = filteredData.map((row, index, data) => {
//     const gexValue = parseFloat(row.gex); // Current day's GEX value
//     const priceToday = parseFloat(row.price); // Current day's SPX price
//
//     // Function to get the previous day's data (backward comparison)
//     const getPrevDayDiff = (daysBack) => {
//       if (index - daysBack >= 0) {
//         const pricePrevDay = parseFloat(data[index - daysBack].price); // Previous day's SPX price
//         return ((priceToday - pricePrevDay) / pricePrevDay) * 100; // Percentage price change
//       }
//       return null; // No previous data available
//     };
//
//     // Calculate price changes for the last 1 to 5 days, handle missing data
//     const day1Diff = getPrevDayDiff(1);
//     const day2Diff = getPrevDayDiff(2);
//     const day3Diff = getPrevDayDiff(3);
//     const day4Diff = getPrevDayDiff(4);
//     const day5Diff = getPrevDayDiff(5);
//
//     return {
//       date: row.date,
//       gexValue,
//       day1Diff: day1Diff !== null ? day1Diff : 0, // Set missing data to 0
//       day2Diff: day2Diff !== null ? day2Diff : 0,
//       day3Diff: day3Diff !== null ? day3Diff : 0,
//       day4Diff: day4Diff !== null ? day4Diff : 0,
//       day5Diff: day5Diff !== null ? day5Diff : 0,
//     };
//   });
//
//   // Mark the most recent day's data as "special" and set all differences to 0
//   if (pairedData.length > 0) {
//     const mostRecentEntry = pairedData[pairedData.length - 1]; // Get the most recent entry
//     mostRecentEntry.day1Diff = 0; // Set day 1 difference to 0
//     mostRecentEntry.day2Diff = 0; // Set day 2 difference to 0
//     mostRecentEntry.day3Diff = 0; // Set day 3 difference to 0
//     mostRecentEntry.day4Diff = 0; // Set day 4 difference to 0
//     mostRecentEntry.day5Diff = 0; // Set day 5 difference to 0
//     mostRecentEntry.isMostRecent = true; // Mark as most recent day
//   }
//
//   return pairedData.filter(item => item !== null);
// };
//
// // Fetch the data from the Zustand store and run the calculation
// const runGammaExpectedMoveCalculation = () => {
//   const store = useStore.getState();
//   const spxGexData = store.spxGexData;
//
//   if (!spxGexData || spxGexData.length === 0) {
//     console.log('No SPX GEX data available in the store.');
//     return;
//   }
//
//   const pairedData = calculateGexAndPriceChange(spxGexData);
//
//   console.log('Paired Data with GEX and Price Change for Days 1-5:', pairedData);
//
//   // Update the Zustand store with the calculated paired data
//   useStore.setState({ expectedMoveData: pairedData });
// };
//
// module.exports = {
//   runGammaExpectedMoveCalculation,
// };


































































// // Working version that populates the data store but with these changes does not allow plotting.
// const useStore = require('../Store/gamma_expected_move_data_store');
//
// // Helper function to calculate mean
// const calculateMean = (data) => {
//   const sum = data.reduce((acc, value) => acc + value, 0);
//   return sum / data.length;
// };
//
// // Helper function to calculate standard deviation
// const calculateStandardDeviation = (data, mean) => {
//   const squaredDiffs = data.map(value => Math.pow(value - mean, 2));
//   const avgSquaredDiff = calculateMean(squaredDiffs);
//   return Math.sqrt(avgSquaredDiff);
// };
//
// // Helper function to calculate skewness
// const calculateSkewness = (data, mean, stdDev) => {
//   const n = data.length;
//   const cubedDiffs = data.map(value => Math.pow(value - mean, 3));
//   const sumCubedDiffs = cubedDiffs.reduce((acc, value) => acc + value, 0);
//   const skewness = (n / ((n - 1) * (n - 2))) * (sumCubedDiffs / Math.pow(stdDev, 3));
//   return skewness;
// };
//
// // Helper function to calculate kurtosis
// const calculateKurtosis = (data, mean, stdDev) => {
//   const n = data.length;
//   const fourthPowerDiffs = data.map(value => Math.pow(value - mean, 4));
//   const sumFourthPowerDiffs = fourthPowerDiffs.reduce((acc, value) => acc + value, 0);
//   const kurtosis = (n * (n + 1)) / ((n - 1) * (n - 2) * (n - 3)) * (sumFourthPowerDiffs / Math.pow(stdDev, 4)) -
//     (3 * Math.pow(n - 1, 2)) / ((n - 2) * (n - 3));
//   return kurtosis;
// };
//
// // Function to calculate and adjust standard deviation bounds based on skewness and kurtosis
// const adjustBoundsForSkewnessKurtosis = (mean, stdDev, skewness, kurtosis) => {
//   const skewnessFactor = 1 + Math.abs(skewness) * 0.1;  // Adjust skewness sensitivity as needed
//   const kurtosisFactor = 1 + Math.abs(kurtosis) * 0.03; // Adjust kurtosis sensitivity as needed
//
//   let upperBound = mean + stdDev;
//   let lowerBound = mean - stdDev;
//
//   if (skewness > 0) {
//     upperBound = mean + skewnessFactor * stdDev;
//   } else if (skewness < 0) {
//     lowerBound = mean - skewnessFactor * stdDev;
//   }
//
//   if (kurtosis > 0) {
//     upperBound *= kurtosisFactor;
//     lowerBound *= kurtosisFactor;
//   } else if (kurtosis < 0) {
//     upperBound /= kurtosisFactor;
//     lowerBound /= kurtosisFactor;
//   }
//
//   return { upperBound, lowerBound };
// };
//
// // Function to calculate GEX statistics for a given day range (day 1 to day 5)
// const calculateGexStatisticsForDays = (pairedData, dayRange) => {
//   const intervals = {
//     'Negative (< -2B)': [],
//     '-2B to -1B': [],
//     '-1B to 0B': [],
//     '0B to 1B': [],
//     '1B to 2B': [],
//     '2B to 3B': [],
//     '3B to 4B': [],
//     '4B to 5B': [],
//     '5B to 6B': [],
//     '6B to 7B': [],
//     '7B+': []
//   };
//
//   pairedData.forEach(({ gexValue, priceDifferencePercentByDay }) => {
//     if (gexValue < -2e9) {
//       intervals['Negative (< -2B)'].push(priceDifferencePercentByDay[dayRange]);
//     } else if (gexValue >= -2e9 && gexValue < -1e9) {
//       intervals['-2B to -1B'].push(priceDifferencePercentByDay[dayRange]);
//     } else if (gexValue >= -1e9 && gexValue < 0) {
//       intervals['-1B to 0B'].push(priceDifferencePercentByDay[dayRange]);
//     } else if (gexValue >= 0 && gexValue < 1e9) {
//       intervals['0B to 1B'].push(priceDifferencePercentByDay[dayRange]);
//     } else if (gexValue >= 1e9 && gexValue < 2e9) {
//       intervals['1B to 2B'].push(priceDifferencePercentByDay[dayRange]);
//     } else if (gexValue >= 2e9 && gexValue < 3e9) {
//       intervals['2B to 3B'].push(priceDifferencePercentByDay[dayRange]);
//     } else if (gexValue >= 3e9 && gexValue < 4e9) {
//       intervals['3B to 4B'].push(priceDifferencePercentByDay[dayRange]);
//     } else if (gexValue >= 4e9 && gexValue < 5e9) {
//       intervals['4B to 5B'].push(priceDifferencePercentByDay[dayRange]);
//     } else if (gexValue >= 5e9 && gexValue < 6e9) {
//       intervals['5B to 6B'].push(priceDifferencePercentByDay[dayRange]);
//     } else if (gexValue >= 6e9 && gexValue < 7e9) {
//       intervals['6B to 7B'].push(priceDifferencePercentByDay[dayRange]);
//     } else if (gexValue >= 7e9) {
//       intervals['7B+'].push(priceDifferencePercentByDay[dayRange]);
//     }
//   });
//
//   for (const [interval, data] of Object.entries(intervals)) {
//     if (data.length > 0) {
//       const mean = calculateMean(data);
//       const stdDev = calculateStandardDeviation(data, mean);
//       const skewness = calculateSkewness(data, mean, stdDev);
//       const kurtosis = calculateKurtosis(data, mean, stdDev);
//
//       const { upperBound: upperBound1, lowerBound: lowerBound1 } = adjustBoundsForSkewnessKurtosis(mean, stdDev, skewness, kurtosis);
//       const { upperBound: upperBound2, lowerBound: lowerBound2 } = adjustBoundsForSkewnessKurtosis(mean, 2 * stdDev, skewness, kurtosis);
//
//       console.log(`Day ${dayRange}: Interval: ${interval}`);
//       console.log(`  Mean: ${mean.toFixed(2)}%`);
//       console.log(`  1 Std Dev: ${lowerBound1.toFixed(2)}% to ${upperBound1.toFixed(2)}%`);
//       console.log(`  2 Std Dev: ${lowerBound2.toFixed(2)}% to ${upperBound2.toFixed(2)}%`);
//     } else {
//       console.log(`Day ${dayRange}: Interval: ${interval}`);
//       console.log('  No data available for this interval.');
//     }
//   }
// };
//
// // Function to calculate GEX value for the current day and the % price change from the previous day
// const calculateGexAndPriceChange = (spxGexData) => {
//   const filteredData = spxGexData.filter((row) => row.gex && row.price);
//
//   const pairedData = filteredData.map((row, index, data) => {
//     const gexValue = parseFloat(row.gex);
//     const priceToday = parseFloat(row.price);
//     const priceDifferencePercentByDay = {};
//
//     for (let day = 1; day <= 5; day++) {
//       const prevDayRow = data[index - day];
//       if (prevDayRow) {
//         const pricePrevDay = parseFloat(prevDayRow.price);
//         const priceDifferencePercent = ((priceToday - pricePrevDay) / pricePrevDay) * 100;
//         priceDifferencePercentByDay[day] = priceDifferencePercent;
//       } else {
//         priceDifferencePercentByDay[day] = null; // No data for that day
//       }
//     }
//
//     return {
//       date: row.date,
//       gexValue,
//       priceDifferencePercentByDay,
//     };
//   });
//
//   return pairedData.filter(item => item !== null);
// };
//
// // Function to apply VVIX filter and categorize results
// const applyVvixFilter = (pairedData, vvixData) => {
//   const noFilterData = pairedData;
//
//   const vvixBelowFilterData = pairedData.filter(moveEntry => {
//     const vvixEntry = vvixData.find(vvix => vvix.date === moveEntry.date);
//     return vvixEntry && vvixEntry.vvix_avg_z_score < vvixEntry.vvix_super_smooth_z_score;
//   });
//
//   const vvixAboveFilterData = pairedData.filter(moveEntry => {
//     const vvixEntry = vvixData.find(vvix => vvix.date === moveEntry.date);
//     return vvixEntry && vvixEntry.vvix_avg_z_score > vvixEntry.vvix_super_smooth_z_score;
//   });
//
//   return { noFilterData, vvixBelowFilterData, vvixAboveFilterData };
// };
//
// // Fetch the data from the Zustand store and run the calculation
// const runGammaExpectedMoveCalculation = () => {
//   const store = useStore.getState();
//   const setExpectedMoveDataForScenario = useStore.getState().setExpectedMoveDataForScenario;
//
//   const spxGexData = store.spxGexData;
//   const vvixData = store.vvixData;
//
//   // Check if data is available
//   if (!spxGexData || spxGexData.length === 0) {
//     console.log('No SPX GEX data available in the store.');
//     return;
//   }
//
//   if (!vvixData || vvixData.length === 0) {
//     console.log('No VVIX data available in the store.');
//     return;
//   }
//
//   // Calculate GEX and price changes
//   const pairedData = calculateGexAndPriceChange(spxGexData);
//
//   // Apply VVIX filters
//   const { noFilterData, vvixBelowFilterData, vvixAboveFilterData } = applyVvixFilter(pairedData, vvixData);
//
//   // For days 1 to 5, store results for each scenario
//   for (let day = 1; day <= 5; day++) {
//     console.log(`No Filter Scenario for Day ${day}:`);
//     const expectedMoveDataNoFilter = calculateGexStatisticsForDays(noFilterData, day);
//     setExpectedMoveDataForScenario('noFilter', day, expectedMoveDataNoFilter); // Store no-filter data
//
//     console.log(`VVIX Below Filter Scenario for Day ${day} (vvix_avg_z_score < vvix_super_smooth_z_score):`);
//     const expectedMoveDataVvixBelow = calculateGexStatisticsForDays(vvixBelowFilterData, day);
//     setExpectedMoveDataForScenario('vvixBelow', day, expectedMoveDataVvixBelow); // Store VVIX below-filter data
//
//     console.log(`VVIX Above Filter Scenario for Day ${day} (vvix_avg_z_score > vvix_super_smooth_z_score):`);
//     const expectedMoveDataVvixAbove = calculateGexStatisticsForDays(vvixAboveFilterData, day);
//     setExpectedMoveDataForScenario('vvixAbove', day, expectedMoveDataVvixAbove); // Store VVIX above-filter data
//   }
//
//   // Update paired data to store if necessary
//   useStore.setState({ expectedMoveData: pairedData });
// };
//
// module.exports = {
//   runGammaExpectedMoveCalculation,
// };


















